import React, { Component } from "react";
import axios from "axios";
import { awsUrl } from "../config";
import SmallLoader from "./smallLoader";
import "../index.css";
import * as xlsx from "xlsx";

class UploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      awaitingFile: false,
      parseError: false,
      parseMessage: "",
      urlSuccess: false,
      signedUrlList: [],
      downloadInProgress: false,
    };
  }

  handleOcrParse = (data, fileName) => {
    let response = {
      url: "",
      error: false,
    };
    if (data.fileType === "success") {
      response.url = data.url;
    } else if (data.fileType === "error") {
      let mailParams = {
        fileName,
      };
      axios
        .post(awsUrl + "/api/sendMail", mailParams, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((data) => console.log("A mail has been sent!"))
        .catch((err) => console.log("Failed to send mail"));
      response.error = true;
    } else {
      response.error = true;
    }

    return response;
  };

  s3Poller = (fileName, originalFileName) => {
    return new Promise((resolve, reject) => {
      try {
        let encodedFileName = encodeURIComponent(`${originalFileName}.xlsx`);
        axios
          .get(awsUrl + `/api/s3Poller/${fileName}/${encodedFileName}`)
          .then((result) => {
            if (result.data.fileType) {
              let { url, error } = this.handleOcrParse(result.data, fileName);
              if (error || !url) {
                reject("error in s3 poller");
              } else {
                resolve(url);
              }
            } else {
              setTimeout(async () => {
                try {
                  let url = await this.s3Poller(fileName, originalFileName);
                  resolve(url);
                } catch (error) {
                  reject(error);
                }
              }, 3000);
            }
          })
          .catch((err) => {
            console.log("Error", err);
            reject(err);
          });
      } catch (error) {
        reject(error);
      }
    });
  };

  putToS3 = (file) => {
    return new Promise(async (resolve, reject) => {
      try {
        let fileData = file;
        let fileName = fileData.name;
        let fileNameArray = fileName.split(".");
        fileNameArray.pop();
        let originalFileName = fileNameArray.join(".");
        let fileType = fileData.type;
        let options = { headers: { "Content-Type": fileType } };

        let signedUrlData = await axios.post(awsUrl + "/api/getSignedUrl", {
          fileName,
          fileType,
        });
        let { signedUrl, key,timeStamp } = signedUrlData.data;

        await axios.put(signedUrl, fileData, options);
        axios
          .post(awsUrl + "/api/fileUploadRti", { key, fullFileName: fileName,timeStamp })
          .then(async (response) => {
            const fileName = response.data.fileName;
            const folder = fileName.split(".")[0];
            let url = await this.s3Poller(folder, originalFileName);
            resolve({ url, fileName: originalFileName });
          })
          .catch((error) => {
            console.log("error", error);
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  };

  uploadFile = async (event) => {
    event.preventDefault();
    this.setState({
      parseError: false,
      urlSuccess: false,
      signedUrlList: [],
      awaitingFile: true,
    });

    let { file: files } = this.state;

    // create signed URLs and upload all files to S3
    let putToS3PromiseList = [];
    for (let file of files) {
      putToS3PromiseList.push(this.putToS3(file));
    }

    let resolvedPutToS3Promises = await Promise.allSettled(putToS3PromiseList);

    let errorInAllFiles = true;
    let signedUrlList = [];
    for (let data of resolvedPutToS3Promises) {
      if (data.status === "fulfilled" && data.value) {
        errorInAllFiles = false;
        signedUrlList.push({
          url: data.value.url,
          fileName: data.value.fileName,
        });
      }
    }

    if (errorInAllFiles) {
      this.setState({
        parseError: true,
        awaitingFile: false,
        parseMessage:
          "Oops! Something went wrong. Please contact admin@insurecomp.com for further assistance.",
      });
    } else {
      this.setState({
        parseMessage: "Parsing complete!",
        parseError: false,
        urlSuccess: true,
        signedUrlList,
        awaitingFile: false,
      });
    }
  };

  handleFileUpload = (event) => {
    this.setState({ file: event.target.files });
  };

  fetchExcelData = (signedUrl, fileName) => {
    return new Promise(async (resolve, reject) => {
      try {
        let resp = await axios({
          method: "get",
          url: signedUrl,
          responseType: "blob",
        });
        resolve({ data: resp.data, fileName });
      } catch (error) {
        reject(error);
      }
    });
  };

  handleDownload = async () => {
    try {
      let { signedUrlList } = this.state;
      this.setState({ downloadInProgress: true });

      let getExcelDataPromiseList = [];
      for (let data of signedUrlList) {
        getExcelDataPromiseList.push(
          this.fetchExcelData(data.url, data.fileName)
        );
      }

      let excelResponseList = await Promise.allSettled(getExcelDataPromiseList);

      // create an empty workbook to store the aggregated data
      let aggregateWorkbook = xlsx.utils.book_new();

      let allSheetsData = [];
      let columnsStyle = [];
      for (let data of excelResponseList) {
        if (data.status === "fulfilled") {
          // excel allows max 31 chars sheet name
          let sheetName = data.value.fileName;
          if (sheetName.length > 31) {
            sheetName = sheetName.substring(0, 31);
          }

          // add sheet1 data to the aggregate workbook as a new sheet
          let arrayBufferData = await data.value.data.arrayBuffer();
          let clienWorkbook = xlsx.read(arrayBufferData, {
            type: "array",
            cellStyles: true,
          });
          let clientWorksheet =
            clienWorkbook.Sheets[clienWorkbook.SheetNames[0]];
          xlsx.utils.book_append_sheet(
            aggregateWorkbook,
            clientWorksheet,
            sheetName,
            true
          );

          /* copy the styling of the client worksheet to apply 
          to the aggregate sheet */
          columnsStyle = clientWorksheet["!cols"];

          // append all the rows to a global list to prepare the aggregate sheet
          let clientWorksheetRowsList = xlsx.utils.sheet_to_json(
            clientWorksheet,
            { raw: false }
          );
          allSheetsData = [...allSheetsData, ...clientWorksheetRowsList];
        }
      }

      // attach the aggregate sheet to the final excel
      let aggregateSheet = xlsx.utils.json_to_sheet(allSheetsData);
      aggregateSheet["!cols"] = columnsStyle;
      xlsx.utils.book_append_sheet(
        aggregateWorkbook,
        aggregateSheet,
        "Aggregate",
        true
      );

      // create link to download the aggregate excel file
      let aggregateArray = xlsx.write(aggregateWorkbook, {
        bookType: "xlsx",
        type: "array",
      });
      let aggregateBlob = new Blob([aggregateArray]);
      let aggregateWorkbookLink = document.createElement("a");
      aggregateWorkbookLink.href = window.URL.createObjectURL(aggregateBlob);
      aggregateWorkbookLink.download = "Aggregate.xlsx";
      aggregateWorkbookLink.id = "aggregate";
      document.body.appendChild(aggregateWorkbookLink);
      aggregateWorkbookLink.click();
      document.body.removeChild(aggregateWorkbookLink);

      this.setState({ downloadInProgress: false });
    } catch (error) {
      this.setState({ downloadInProgress: false });
      console.log(error);
    }
  };

  render() {
    const {
      awaitingFile,
      urlSuccess,
      parseError,
      parseMessage,
      downloadInProgress,
    } = this.state;

    return (
      <div className="container-fluid">
        <form className="col-md-12">
          <p>Select quote document(s) for analysis</p>
          <div className="form-group">
            <input
              label="upload file"
              type="file"
              multiple
              onClick={() => {
                this.setState({
                  awaitingFile: false,
                  parseError: false,
                  parseMessage: "",
                  urlSuccess: false,
                  signedUrlList: [],
                });
              }}
              onChange={this.handleFileUpload}
            />
          </div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.uploadFile}
          >
            Submit
          </button>
        </form>
        <div className="col-md-12 mt-2">
          {awaitingFile && (
            <span>
              Waiting for file creation <SmallLoader />
            </span>
          )}
          {urlSuccess && (
            <div>
              <button
                className="file-download-button"
                onClick={this.handleDownload}
              >
                Click here to download your file.
              </button>
              {downloadInProgress && <SmallLoader />}
            </div>
          )}
          {parseError && (
            <div>
              <p>{parseMessage}</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default UploadForm;
